<script setup lang="ts">

</script>

<template>
  <div class="homepage">
    <img src="/images/under-construction/img.gif" alt="site-under-construction">
  </div>
</template>

<style scoped>
.homepage {
  position: relative;
  min-height: 648px;
  display: flex;
  align-items: center;
  justify-content: center;

  background-image: url("/images/banner/homepage-img.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
</style>